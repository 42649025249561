import React from 'react';
import styled from 'styled-components';

import AlertDialog from 'src/common/components/base/AlertDialog';
import Button from 'src/common/components/base/Button';
import Input from 'src/common/components/base/Input';

import background from 'src/assets/shroombox-v2.png';

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
`;

const Inner = styled.div`
  max-width: 700px;
  padding: 20px 20px 100px 20px;
`;

const InputHolder = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 40%;
  min-width: 360px;
  opacity: 0.1;
  z-index: -1;
`;

class ComingSoon extends React.Component {
  state = {
    alertMessage: '',
    value: '',
  };

  handleAlertClose = () => this.setState({ alertMessage: '' });

  onEmailInputChange = e => this.setState({ value: e.target.value });

  onSubmit = () => {
    if (!this.state.value) {
      return;
    }

    const input = document.getElementById('email-input');

    if (!input.checkValidity()) {
      return this.setState({
        alertMessage: 'Please enter a valid email address',
      });
    }

    return this.setState({
      alertMessage:
        'We have not setup this functionality yet! Please send us an email at info@shroombox.com',
    });
  };

  render() {
    return (
      <Main>
        <Inner>
          <h1>Something Big is Coming</h1>
          <p>
            The cat is out of the bag: the Shroombox is just around the corner.
            We know you&apos;re excited, we are too. Join our e-mail list to
            stay abreast of Shroombox happenings and, most importantly, be among
            the first to receive the ultimate Shroombox launch date.
          </p>
          <InputHolder>
            <Input
              id="email-input"
              label="Email Address"
              onChange={this.onEmailInputChange}
              type="email"
              value={this.state.value}
              style={{ marginRight: 10 }}
            />
            <Button onClick={this.onSubmit} small>
              Submit
            </Button>
          </InputHolder>
        </Inner>
        <BackgroundImage alt="shroombox-drawing" src={background} />
        <AlertDialog
          content={this.state.alertMessage}
          handleClose={this.handleAlertClose}
          open={Boolean(this.state.alertMessage)}
          title="Oops!"
        />
      </Main>
    );
  }
}

export default ComingSoon;
