import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from './Button';

const AlertDialog = ({ handleClose, open, title, content }) => {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`alert-dialog-${title}`}
      aria-describedby={`alert-dialog-${title}-description`}
    >
      <DialogTitle id={`alert-dialog-${title}`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`alert-dialog-${title}-description`}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button small onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
