import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const baseInputStyles = theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '&:invalid': {
      borderColor: 'red',
    },
    '&:focus, :invalid': {
      borderColor: '#80bdff',
    },
  },
});

const InputWithStyles = withStyles(baseInputStyles)(InputBase);

const styles = {
  shrink: {
    top: '-17px !important',
  },
  root: {
    top: -10,
    left: 10,
  },
};

const Input = ({ classes, id, label, onChange, type, value, ...rest }) => (
  <FormControl {...rest}>
    <InputLabel
      classes={{ shrink: classes.shrink, root: classes.root }}
      htmlFor={id}
    >
      {label}
    </InputLabel>
    <InputWithStyles id={id} onChange={onChange} type={type} value={value} />
  </FormControl>
);

export default withStyles(styles)(Input);
